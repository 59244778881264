import React from 'react';
import {MainSiteWrapper} from "../components/_mainComponents";
import {HelmetComponent, Layout} from "../components/Layout";
import Header from "../components/Header";
import Jumbotron from "../components/Jumbotron";
import Whoami from "../components/Whoami";

const NotFound = () => {
    return (
        <Layout>
            404
        </Layout>
    );
};

export default NotFound;